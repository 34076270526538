/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Image } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="mt--30 pb--0 pt--0" name={"einleitung-3"}>
          
          <ColumnWrap className="column__flex --shape4 --center el--2 pb--0 pl--0 pr--0 pt--0 flex--center" anim={null} animS={null} style={{"backgroundColor":"var(--color-custom-1)"}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3" anim={"5"} animS={"3"} style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--left fs--62 title-box--invert" content={"Professioneller Reinigungsservice Olomouc"}>
              </Title>

              <Text className="text-box text-box--left text-box--invert pl--06" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 btn-box--left fs--24 btn-box--invert mt--50" innerClassName="pb--02 pl--0 pr--0" href={"/de/cenik"} content={"Presliste"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pt--0" anim={null} animS={null} style={{"maxWidth":""}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/121/fullscreen_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/121/fullscreen_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/121/fullscreen_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/121/fullscreen_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/121/fullscreen_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/121/fullscreen_s=2000x_.jpg 2000w"} content={null} position={{"x":"-58.63%","y":"-2.05%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"information"}>
          
          <ColumnWrap className="column__flex --justify el--3 mb--20 mt--10 pb--06 pl--02 pr--02 pt--06" style={{"maxWidth":1400}} columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 --left" anim={"2"} animS={"3"} style={{"maxWidth":410}}>
              
              <Title className="title-box" style={{"maxWidth":300}} content={"Wohnungen ab 800 CZK"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3 --left" anim={"2"} animS={"3"} style={{"maxWidth":410}}>
              
              <Title className="title-box" style={{"maxWidth":300}} content={"Büroreinigung ab 1200 CZK"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3 --left" anim={"2"} animS={"3"} style={{"maxWidth":410}}>
              
              <Title className="title-box" style={{"maxWidth":300}} content={"Lagerreinigung ab 25 CZK / m2"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--0 pt--0" name={"kontakt"}>
          
          <ColumnWrap className="column__flex --shape4 --justify el--2 pb--08 pl--0 pr--0 pt--08 flex--center" style={{"backgroundColor":"var(--color-blend--95)"}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3 pb--40 pt--40" anim={"5"} animS={"3"} style={{"maxWidth":510}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1);\">Haben sie interesse an der professionellen Reinigung?</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--right pb--40 pt--40" style={{"maxWidth":510}}>
              
              <Button className="btn-box btn-box--shape3 btn-box--invert" href={"/de/kontakt"} content={"Kontaktieren Sie uns"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"information-2"}>
          
          <ColumnWrap className="column__flex --justify el--2 mb--20 mt--10 pb--06 pl--02 pr--02 pt--06" style={{"maxWidth":1400}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3 --left" anim={"5"} animS={"3"} style={{"maxWidth":""}}>
              
              <Title className="title-box" style={{"maxWidth":550}} content={"Es ist nicht nur ein Sprichwort: Reinheit - die Hälfte des Lebens."}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textes, die Schriftgröße und den Zeilenabstand ändern und sogar die maximale Breite des Textes in Pixeln einstellen."}>
              </Text>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--0" name={"information-3"}>
          
          <ColumnWrap className="column__flex --shape4 --center el--2 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"backgroundColor":"var(--color-blend--95)"}} columns={"2"}>
            
            <ColumnWrapper className="pb--0 pt--0" style={{"maxWidth":""}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/121/img-2_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/121/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/121/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/121/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/121/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/121/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s3 --left pb--60 pt--60" anim={"4"} animS={"3"} style={{"maxWidth":510}}>
              
              <Title className="title-box" content={"Professioneller Reinigungsservice Olomouc"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"partei"} style={{"backgroundColor":"var(--color-custom-1)"}}>
          
          <ColumnWrap className="column__flex el--2 mb--20 mt--10 pb--06 pl--02 pr--02 pt--06" style={{"maxWidth":1400}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--24 title-box--invert" style={{"maxWidth":""}} content={"Professionelle Reinigungsfirma Olomouc"}>
              </Title>

              <Text className="text-box text-box--invert" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--24 title-box--invert" style={{"maxWidth":""}} content={"Kontakt"}>
              </Title>

              <Text className="text-box text-box--invert" content={"+49 797 811 2X05<br>info@vase-stranky.com"}>
              </Text>

              <Text className="text-box text-box--invert" content={"<a href=\"https://saywebpage.com\">Webproduktion stránek</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}